import React from "react";
import "./spinner.styles.scss";

const SpinnerContainer = () => {


  return (
    <div className="blocks">
  <div className="block orange"></div>
  <div className="block blue"></div>
</div>
  );
};

export default SpinnerContainer;
